import React, { useContext, useEffect, useState } from 'react';
import './CorporateExploreInsight.css';
import eyeIcon from '../../assets/images/password-icon.png';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import CEIKeyData from '../../components/CorporateExploreInsights/CEIKeyData/CEIKeyData';
import CEIFinancial from '../../components/CorporateExploreInsights/CEIFinancials/CEIFinancials';
import SearchSuggestions from '../../components/SearchSuggestions/SearchSuggestions';
import CIEContact from '../../components/CorporateExploreInsights/CIEContact/CIEContact';
import CIEDocument from '../../components/CorporateExploreInsights/CIEDocument/CIEDocument';
import CEIPeople from '../../components/CorporateExploreInsights/CEIPeople/CEIPeople';
import CEILatestActivity from '../../components/CorporateExploreInsights/CEILatestActivity/CEILatestActivity';
import CEIDisableAndSignificantControl from '../../components/CorporateExploreInsights/CEIDisableAndSignificantControl/CEIDisableAndSignificantControl';
import WatchList from '../../components/CorporateExploreInsights/CEIWatchList/CEIWatchList';
import CEIShareholder from '../../components/CorporateExploreInsights/CEIShareholder/CEIShareholder';
import CorporateExploreInsightSidebar from '../../components/CorporateExploreInsights/CorporateExploreInsightSidebar/CorporateExploreInsightSidebar';
import CorporateExploreInsightSidebarHorizental from '../../components/CorporateExploreInsights/CorporateExploreInsightSidebar/CorporateExplorerInsightSideBarHorizental';
import CorporateExploreInsightOverview from './CorporateExploreInsightOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CEIPersonInsightsLatestOverview from './CEIPersonInsightsLatest/CEIPersonInsightsLatestOverview';
import CorporateExploreInsightResults from './CorporateExploreInsightResults/CorporateExploreInsightResults';
import { AppContext } from '../../context/Context';
import {
  getCompanyInfoByCompanyNumber,
  getPersonInsights,
  getRelationShips,
} from '../../api/corporateExplorer/corporateExplorer';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { useNavigate } from 'react-router';

export default function CorporateExploreInsight() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('All');

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [selectedView, setSelectedView] = useState('Overview');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {
    companiesAndOfficers,
    setCompaniesAndOfficers,
    officer,
    company,
    setCompany,
    setOfficer,
    user,
    setHandleNavigation,
    handleNavigation,
  } = useContext(AppContext);

  const { isLoading, setIsLoading } = useLoading();

  const [staticHolder, setStaticHolder] = useState([
    'Overview',
    'People & Contacts',
    'Financials',
    'Control & Ownership',
    'Credit Risk',
    'Competition',
    'Property',
    'Documents',
    'Shareholders',
  ]);

  useEffect(() => {
    const fetchCompanyData = async company => {
      try {
        setIsLoading(true);
        const data = await getCompanyInfoByCompanyNumber(
          company?.company_number
        );
        setSelectedCompany(data?.companyInfo);
        setAccountsInfo(data?.accountInfo);
        setSelectedOfficer(null);
        setIsLoading(false);
        setCompaniesAndOfficers(null);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching company data:', error);
      }
    };

    const fetchOfficerData = async officer => {
      try {
        setIsLoading(true);

        let combination = {
          combinationType: 'officer',
          ids: [{ type: 'officer', id: officer?.person_number }],
          userId: user?.FirebaseUser?.user_id,
        };

        let nodeGraph = await getRelationShips(combination);
        combination['graphs'] = nodeGraph?.result?.graphs;
        const data = await getPersonInsights(combination);
        setSelectedOfficer(data);
        setSelectedCompany(null);
        setIsLoading(false);
        setCompaniesAndOfficers(null);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching officer data:', error);
        setCompaniesAndOfficers(null);
      }
    };

    if (company) {
      fetchCompanyData(company);
    }

    if (officer) {
      fetchOfficerData(officer);
    }
  }, [officer, company]);


  useEffect(() => {
  }, [handleNavigation]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="corporate-explore-search-insight-main">
          <div className="Corporate-explore-search-insight-title">Insight</div>
          <SearchSuggestions
            setSelectedCompany={setSelectedCompany}
            setAccountsInfo={setAccountsInfo}
            setSelectedOfficer={setSelectedOfficer}
            setSelectedTab={setSelectedTab}
            handleNavigation={handleNavigation}
          />
          <div className="corporate-explore-insight-watch-list">
            <span>
              <FontAwesomeIcon
                icon={faEye}
                style={{ color: '#fff', width: '25px', borderRadius: '5px' }}
              />
            </span>
            <div className="corporate-explore-insight-watch-list-text">
              Watch List
            </div>
          </div>
          <button className="upgrade-button">UPGRADE</button>
        </div>

        <div className="Corporate-explore-insight-main">
          <div className="corporate-explorer-insights-main-row">
            {/* Desktop view */}
            {windowWidth >= 1200 && (
              <div style={{ display: 'flex', width: '100%' }}>
                {(selectedCompany || selectedOfficer) && (
                  <CorporateExploreInsightSidebar
                    selectedCompany={selectedCompany}
                    onSelectMenuItem={setSelectedView}
                  />
                )}
                {selectedCompany && (
                  <div className="corporate-explore-content">
                    {staticHolder?.includes(selectedView) &&
                      selectedCompany && (
                        <CorporateExploreInsightOverview
                          selectedCompany={selectedCompany}
                          accountInfo={accountInfo}
                        />
                      )}
                  </div>
                )}
                <div className="corporate-explore-content"></div>
                {selectedOfficer && (
                  <div className="corporate-explore-content">
                    <CEIPersonInsightsLatestOverview
                      selectedOfficer={selectedOfficer}
                    />
                  </div>
                )}
              </div>
            )}

            {/* Mobile view */}
            {windowWidth < 1200 && (
              <div style={{ backgroundColor: 'white', width: '100%' }}>
                {(selectedCompany || selectedOfficer) && (
                  <CorporateExploreInsightSidebarHorizental
                    selectedCompany={selectedCompany}
                    onSelectMenuItem={setSelectedView}
                  />
                )}
                {staticHolder?.includes(selectedView) && selectedCompany && (
                  <div className="corporate-explore-content">
                    <CorporateExploreInsightOverview
                      selectedCompany={selectedCompany}
                      accountInfo={accountInfo}
                    />
                  </div>
                )}
                {selectedOfficer && (
                  <div className="corporate-explore-content">
                    <CEIPersonInsightsLatestOverview
                      selectedOfficer={selectedOfficer}
                      forceMobileView={true}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Person insights */}
          <div className="corporate-explorer-insights-main-row">
            {/* Desktop view */}
            {windowWidth >= 1200 && (
              <div style={{ display: 'flex', width: '100%' }}>
                {/* Additional content if needed */}
              </div>
            )}

            {/* Mobile view */}
            {windowWidth < 1200 && selectedCompany && (
              <div style={{ backgroundColor: 'white', width: '100%' }}>
                {/* Additional content if needed */}
              </div>
            )}
          </div>

          {companiesAndOfficers && (
            <div>
              <CorporateExploreInsightResults
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
