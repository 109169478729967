import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import { AppContext } from './context/Context.js';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { useLoading } from './context/LoadingContext/LoadingContext.js';
import LoadingScreen from './components/LoadingScreen/LoadingScreen.js';
import PaymentCancel from './pages/Payment/PaymentCancel.js';
import PricingPage from './pages/PricingPage/PricingPage.jsx';
import { useUserSetupContext } from './context/UserLoadingContext/UserLoadingContext.js';
import { InitializeGoogleAnalytics } from './googleAnalytics/googleAnalytics.js';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.jsx';
import { triggerGoogleAnalyticsEvent } from './utils/googleAnalyticsEvent.js';
import AuthProvider from './context/AuthProvider.js';
import MainLayout from './MainLayout.jsx';

function App() {
  const UNPROTECTED_ROUTES = [
    '/ccj-dashboard/judgement-checker',
    '/ccj-dashboard/judgement-checker-business',
    '/ccj-dashboard/report',
    '/ccj-dashboard/privacy-policy',
    '/ccj-dashboard/terms-and-conditions',
    '/cl-dashboard/search-entites',
    '/cl-dashboard/cl-graph',
    '/cei-dashboard/cei-corporate-explore-insight',
    '/fo-dashboard/foregn-uk-ownership',
    '/bi-dashboard/background-investigation',
    '/fbf-dashboard/feedbackform',
    '/blog-dashboard/view',
    '/blog-dashboard/post',
    '/ccj-dashboard/check-review-business',
    '/ccj-dashboard/judgement-checker-your-detail',
    '/ccj-dashboard/judgement-checker-person',
    '/profile/customer-admin',
    '/profile/accounts-usage',
    '/profile/judgement-checker-reports',
    '/payment-cancel',
    '/sign-up-post',
    '/login-post',
  ];
  const { isLoading } = useLoading();
  const { isUserSetup } = useUserSetupContext();
  const [user, setUser] = useState(null);
  const [clGraph, setClGraph] = useState(null);
  const [companiesAndOfficers, setCompaniesAndOfficers] = useState(null);
  const [company, setCompany] = useState(null);
  const [officer, setOfficer] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
  const [searchedEntities, setSearchedEntites] = useState([]);
  const [sideBarInfo, setSideBarInfo] = useState({});
  const [
    corporateExplorerSelectedFilterItem,
    setCorporateExplorerSelectedFilterItem,
  ] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [appData, setAppData] = useState({
    userInfo: {},
    groups: [],
    selectedGroup: {},
    sideBarExpanded: true,
  });
  const [judgementChecker, setJudgementChecker] = useState({
    businessJudgementChecker: {},
    personJudgementChecker: {},
  });
  const [corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters] =
    useState({
      officerFilters: null,
      companyFilters: null,
    });
  const [handleNavigation, setHandleNavigation] = useState(null)
  
  useEffect(() => {
    const googleAnalytics = async () => {
      InitializeGoogleAnalytics();
      await triggerGoogleAnalyticsEvent('page_visits', 'home');
    };
    googleAnalytics();
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isUserSetup && <LoadingScreen />}
      <AppContext.Provider
        value={{
          appInfo: appData,
          toggleSidebar: toggleSidebar,
          setToggleSidebar: setToggleSidebar,
          setAppInfo: setAppData,
          userLoggedIn: isUserLoggedIn,
          setIsUserLoggedIn: setIsUserLoggedIn,
          judgementChecker: judgementChecker,
          setJudgementChecker: setJudgementChecker,
          setUser: setUser,
          user: user,
          searchedEntities: searchedEntities,
          setSearchedEntites: setSearchedEntites,
          sideBarInfo: sideBarInfo,
          setSideBarInfo: setSideBarInfo,
          corporateExplorerSelectedFilterItem:
            corporateExplorerSelectedFilterItem,
          setCorporateExplorerSelectedFilterItem:
            setCorporateExplorerSelectedFilterItem,
          setClGraph,
          clGraph,
          companiesAndOfficers,
          setCompaniesAndOfficers,
          officer,
          setOfficer,
          company,
          setCompany,
          corporateLinksAdvanceFilters,
          setCorporateLinksAdvanceFilters,
          handleNavigation
          , setHandleNavigation
        }}
      >
        <BrowserRouter>
          <AuthProvider unprotectedRoutes={UNPROTECTED_ROUTES}>
            <ErrorBoundary>
              <Routes>
                <Route>
                  <Route element={<Home />} path="/" />
                  <Route element={<Home />} path="/home" />
                  <Route element={<PaymentCancel />} path="/payment-cancel" />
                  <Route element={<PricingPage />} path="/pricing-page" />
                  <Route element={<MainLayout />} path="/*" />
                </Route>
              </Routes>
            </ErrorBoundary>
          </AuthProvider>
        </BrowserRouter>
        <ToastContainer />
      </AppContext.Provider>
    </>
  );
}

export default App;
