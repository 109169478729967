import React from 'react';
import './CEIKeyData.css';
import {
  calculateConfirmationStatementOverdueDays,
  calculateYearsUntilNow,
  capitalizeFirstLetter,
  getStatusStringFromChar,
  getStatusStyle,
  isVowel,
} from '../../../utils/helper';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import CorporateExploreInsightLogo from '../../../pages/CorporateExploreInsight/CorporateExploreInsightLogo/CorporateExploreInsightLogo';
import CompaniesLogo from '../../../assets/images/CompaniesLogo.png';
import { COMPANIES_HOUSE_DETAIL } from '../../../utils/constants';

const CEIKeyData = ({ selectedCompany }) => {
  const getDate = dateString => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const renderConfirmationStatement = company => {
    if (!company) return;
    const { last_made_up_to = '', next_due = '' } =
      company?.confirmation_statement || {};

    // Calculate the difference in days, months, or years
    const lastDateMoment = moment(last_made_up_to);
    const currentDateMoment = moment();
    const diffYears = currentDateMoment.diff(lastDateMoment, 'years');
    const diffMonths = currentDateMoment.diff(lastDateMoment, 'months');
    const diffDays = currentDateMoment.diff(lastDateMoment, 'days');

    // Create a readable format for the time difference
    let timeDiffFormatted;
    if (diffYears > 0) {
      timeDiffFormatted = `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    } else if (diffMonths > 0) {
      timeDiffFormatted = `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    } else {
      timeDiffFormatted = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    }

    return (
      <div className="info-item">
        <strong>Confirmation</strong>
        {!company?.confirmation_statement?.overdue ? (
          <span className="status-submitted">Submitted</span>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong style={{ color: '#c0392b' }}>Overdue</strong>
            <small style={{ color: '#c0392b' }}>
              Confirmation statement overdue by{' '}
              {calculateConfirmationStatementOverdueDays(
                company?.confirmation_statement?.next_due
              )}{' '}
              days
            </small>
          </div>
        )}
        <small>Last submitted on {getDate(last_made_up_to)}</small>
        <small>Next confirmation {getDate(last_made_up_to)}</small>
        <small>Due by {getDate(next_due)}</small>
        <small>Last change occurred {timeDiffFormatted}</small>
      </div>
    );
  };

  const renderAccountStatement = accounts => {
    if (!accounts) return;

    const { last_accounts, next_accounts } = accounts;

    // Calculate the time difference for last accounts
    const lastAccountsMoment = last_accounts.made_up_to
      ? moment(last_accounts.made_up_to)
      : null;
    const currentDateMoment = moment();
    let lastAccountsFormatted = 'N/A';
    if (lastAccountsMoment) {
      const diffYears = currentDateMoment.diff(lastAccountsMoment, 'years');
      const diffMonthsTotal = currentDateMoment.diff(
        lastAccountsMoment,
        'months'
      );
      const diffMonths = diffMonthsTotal % 12; // Months excluding full years

      if (diffYears > 0 || diffMonths > 0) {
        lastAccountsFormatted = `(${
          diffYears > 0 ? `${diffYears} year${diffYears > 1 ? 's' : ''} ` : ''
        }${
          diffMonths > 0
            ? `${diffMonths} month${diffMonths > 1 ? 's' : ''}`
            : ''
        } ago)`;
      } else {
        const diffDays = currentDateMoment.diff(lastAccountsMoment, 'days');
        lastAccountsFormatted = `(${diffDays} day${
          diffDays > 1 ? 's' : ''
        } ago)`;
      }
    }

    // Calculate remaining months for next accounts due
    const nextAccountsMoment = next_accounts?.due_on
      ? moment(next_accounts?.due_on)
      : null;
    let dueFormatted = 'N/A';
    if (nextAccountsMoment) {
      const monthsRemaining = nextAccountsMoment?.diff(
        currentDateMoment,
        'months'
      );
      dueFormatted = `(${monthsRemaining} month${
        monthsRemaining !== 1 ? 's' : ''
      } remaining)`;
    }

    return (
      <div className="info-item">
        <strong>Accounts Information</strong>
        {!accounts?.overdue ? (
          <span className="status-submitted">Submitted</span>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong style={{ color: '#c0392b' }}>Overdue</strong>
            <small style={{ color: '#c0392b' }}>
              Confirmation statement overdue by{' '}
              {calculateConfirmationStatementOverdueDays(accounts?.next_due)}{' '}
              days
            </small>
          </div>
        )}

        <small>
          Last accounts submitted for period{' '}
          {getDate(last_accounts?.made_up_to)} {lastAccountsFormatted}
        </small>
        <small>Accounts type was {last_accounts?.type}</small>
        <small>
          Next accounts dated {getDate(next_accounts?.period_end_on)}
        </small>
        <small>
          Due by {getDate(next_accounts?.due_on)} {dueFormatted}
        </small>
      </div>
    );
  };

  const generateCompanyBio = company => {
    if (!company) return;
    const companyName = company?.title || 'Company';
    const companyStatus = company?.company_status
      ? `${isVowel(getStatusStringFromChar(company.company_status).charAt(0)) ? 'an' : 'a'} ${getStatusStringFromChar(company?.company_status)}`
      : null;
    const incorporationDate = company?.date_of_creation
      ? getDate(company?.date_of_creation)
      : null;
    const address = company.address || null;
    const yearsRunning = company.date_of_creation
      ? new Date().getFullYear() -
        new Date(company?.date_of_creation).getFullYear()
      : null;

    const lastConfirmationStatementDate =
      company.confirmation_statement &&
      company.confirmation_statement?.last_made_up_to
        ? getDate(company.confirmation_statement?.last_made_up_to)
        : null;

    return `${companyName} is ${companyStatus} company ${incorporationDate && address ? `incorporated on ${incorporationDate} with the registered office located at ${address}` : ''}. ${yearsRunning ? `${companyName} has been running for ${yearsRunning} years` : ''}. ${lastConfirmationStatementDate ? `According to the latest confirmation statement submitted on ${lastConfirmationStatementDate}.` : ''}`;
  };

  const ccjReport = title => {
    if (title) {
      const baseUrl = window.location.origin;
      const companyName = encodeURIComponent(title);
      const newPath = '/ccj-dashboard/judgement-checker-business';
      const newUrl = `${baseUrl}${newPath}?name=${companyName}`;
      window.open(newUrl, '_blank');
    }
  };

  const onClickCompaniesLogo = selectedCompany => {
    if (!selectedCompany?.company_number) {
      return;
    }
    const url = COMPANIES_HOUSE_DETAIL(selectedCompany?.company_number);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const formateCompanyType = companyName => {
    if (!companyName) return '';

    return companyName
      .split('-')
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <>
      {selectedCompany && (
        <div className="corporate-explore-keyData-main-content">
          <div>
            <CorporateExploreInsightLogo />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <h2 className="corporate-explore-key-data-title">Key Data</h2>
            <div>
              <img
                src={CompaniesLogo}
                alt="Companies Logo"
                width="20"
                height="20"
                onClick={() => onClickCompaniesLogo(selectedCompany)}
              />
            </div>
          </div>
          <p className="corporate-explore-keyData-company-description">
            {generateCompanyBio(selectedCompany)}
          </p>
          {/* <div style={{ marginBottom: "10px" }}>
            <button className="report-btn">BUY A REPORT</button>
            <button className="upgradet-btn">UPGRADE TO PRO</button>
          </div> */}
          <div style={{ marginBottom: '10px' }}>
            <button className="corporate-explore-keyData-report-btn">
              <FontAwesomeIcon
                icon={faFileAlt}
                style={{ marginRight: '5px' }}
              />
              BUY A REPORT
            </button>
            <button className="corporate-explore-keyData-upgrade-btn">
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                style={{ marginRight: '5px' }}
              />
              UPGRADE TO PRO
            </button>
            <button
              className="corporate-explore-ccj-report-btn"
              onClick={() => ccjReport(selectedCompany?.title)}
            >
              <FontAwesomeIcon
                icon={faFileAlt}
                style={{ marginRight: '5px' }}
              />
              CCJ Report
            </button>
          </div>
          <div className="corporate-explore-keyData-company-info">
            <div className="info-item">
              <strong>Name</strong>
              <span>{selectedCompany?.title}</span>
            </div>
            <div className="info-item">
              <strong>Company No.</strong>
              <span>{selectedCompany?.company_number}</span>
            </div>
            {selectedCompany?.type && (
              <div className="info-item">
                <strong>Company Type.</strong>
                <small>{formateCompanyType(selectedCompany?.type)}</small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.originating_registry
              ?.country && (
              <div className="info-item">
                <strong>Incorporated Country.</strong>
                <small>
                  {
                    selectedCompany?.foreign_company_details
                      ?.originating_registry?.country
                  }
                </small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.registration_number && (
              <div className="info-item">
                <strong>Registration number.</strong>
                <small>
                  {
                    selectedCompany?.foreign_company_details
                      ?.registration_number
                  }
                </small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.legal_form && (
              <div className="info-item">
                <strong>Legal form.</strong>
                <small>
                  {selectedCompany?.foreign_company_details?.legal_form}
                </small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.originating_registry
              ?.name && (
              <div className="info-item">
                <strong>Parent registry.</strong>
                <small>
                  {
                    selectedCompany?.foreign_company_details
                      ?.originating_registry?.name
                  }
                </small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.governed_by && (
              <div className="info-item">
                <strong>Governing law .</strong>
                <small>
                  {selectedCompany?.foreign_company_details?.governed_by}
                </small>
              </div>
            )}

            {selectedCompany?.foreign_company_details?.business_activity && (
              <div className="info-item">
                <strong>Objects of the company .</strong>
                <small>
                  {selectedCompany?.foreign_company_details?.business_activity}
                </small>
              </div>
            )}

            <div className="info-item">
              <strong>Status</strong>
              <span
                style={getStatusStyle(
                  selectedCompany?.company_status?.toLowerCase()
                )}
              >
                {getStatusStringFromChar(
                  selectedCompany?.company_status
                )?.toUpperCase()}
              </span>

              <small>
                {getStatusStringFromChar(
                  selectedCompany?.company_status
                )?.toUpperCase()}{' '}
                since incorporation
              </small>
            </div>
            {calculateYearsUntilNow(selectedCompany?.date_of_creation) && (
              <div className="info-item">
                <strong>Incorporation</strong>
                <span>{getDate(selectedCompany?.date_of_creation)}</span>
                <small>
                  Incorporated{' '}
                  {calculateYearsUntilNow(selectedCompany?.date_of_creation)}{' '}
                  ago
                </small>
              </div>
            )}

            {renderConfirmationStatement(selectedCompany)}

            {Array.isArray(selectedCompany?.sic_codes) &&
              selectedCompany?.sic_codes.length > 0 && (
                <div className="info-item">
                  <strong>Classification</strong>
                  <div>
                    {selectedCompany?.sic_codes?.map((sic, index) => (
                      <span key={index} style={{ display: 'block' }}>
                        {index + 1}. {capitalizeFirstLetter(sic)}
                      </span>
                    ))}
                  </div>
                </div>
              )}

            {/* <div className="info-item"></div> */}
            {renderAccountStatement(selectedCompany?.accounts)}
          </div>
        </div>
      )}
    </>
  );
};

export default CEIKeyData;
