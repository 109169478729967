import React, { useEffect, useState } from 'react';
import './CEIFinancials.css';
import {
  calculateDebtRatio,
  calculateNetAssets,
  findAccountsDataByCompanyNumber,
  formatNumber,
} from '../../../utils/helper';
import AreaChart from '../../AreaChart/AreaChart';

const CEIFinancials = ({ selectedCompany, accountInfo }) => {
  const [accountProcessed, setAccountProcessed] = useState({});
  const customData = {
    labels: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
      {
        label: 'Net Assets',
        data: [0, 1.5, 3, 8, 6, -2, -3],
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: true,
      },
      {
        label: 'Total Assets',
        data: [1, 2, 3, 9, 7, 1, 2],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: true,
      },
      {
        label: 'Total Liabilities',
        data: [-1, -1, -1, -3, -2, 0, -1],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: true,
      },
    ],
  };

  useEffect(() => {
    if (accountInfo && selectedCompany) {
      let account = findAccountsDataByCompanyNumber(
        selectedCompany?.company_number,
        accountInfo
      );

      setAccountProcessed(account);
    }
  }, [selectedCompany, accountInfo]);

  return (
    <>
      {selectedCompany && (
        <div className="financials-container">
          <h4 className="financials-title">Financials</h4>
          <div
            style={{ marginBottom: '20px' }}
            className="financials-area-chart"
          >
            <AreaChart data={customData} />
          </div>
          <div className="financials-content">
            <div className="financial-item">
              <span className="label">Year Ended</span>
              <p className="value">2023</p>
            </div>
            <div className="financial-item">
              <span className="label">Total Assets</span>
              <p className="value text-info">
                £{formatNumber(accountProcessed?.total_assets)}
              </p>
            </div>
            <div className="financial-item">
              <span className="label">Total Liabilities</span>
              <p className="value text-secondary">
                £{formatNumber(accountProcessed?.total_liabilities)}
              </p>
            </div>
            <div className="financial-item">
              <span className="label">Net Assets</span>
              <p className="value text-primary">
                £
                {calculateNetAssets(
                  accountProcessed?.total_assets,
                  accountProcessed?.total_liabilities
                )}
              </p>
            </div>
            <div className="financial-item">
              <span className="label">Cash in Bank</span>
              <p className="value">Unreported</p>
            </div>

            <div className="financial-item">
              <span className="label">Employees</span>
              <p className="value">Unreported</p>
            </div>
            <div className="financial-item">
              <span className="label">Turnover</span>
              <p className="value">Unreported</p>
            </div>
            <div className="financial-item">
              <span className="label">Debt Ratio (%)</span>
              <p className="value">
                {calculateDebtRatio(
                  accountProcessed?.total_assets,
                  accountProcessed?.total_liabilities
                )}
                {/* move this in to backend */}
              </p>
            </div>
          </div>
          <a href="#" className="view-financials-link">
            VIEW FINANCIALS
          </a>
        </div>
      )}
    </>
  );
};

export default CEIFinancials;
